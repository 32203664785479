import React from "react";
import { Stack, Typography } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import Logo from "../Logo";
import { style } from "./style";

const Footer = () => {
  return (
    <Stack id="contact" direction="row" sx={style} component="footer">
      <Logo />
      <Stack></Stack>
      <Stack>
        <Stack direction="row">
          <Typography>
            Время приёма звонков
          </Typography>
        </Stack>

        <Stack direction="row">
          <Typography>
            Пн-Пт 9:00 - 16:00
          </Typography>
        </Stack>
        <Stack direction="row">
          <LocalPhoneIcon />
          <Typography>
            <a href="tel:+375297573599">+375 (29) 757-35-99</a>
          </Typography>
        </Stack>
        <Stack direction="row">
          <EmailIcon />
          <Typography>
            <a href="mailto:videoperimetr_by@mail.ru">
              videoperimetr_by@mail.ru
            </a>
          </Typography>
        </Stack>
      </Stack>
      <Stack>
        <Stack direction="row">
          <Typography>Время работы офиса</Typography>
        </Stack>
        <Typography>Пн-Пт 8:00 - 17:00</Typography>
        <Typography>Обед 13:00 - 13:45</Typography>
      </Stack>
      <Stack>
        <Typography>г. Молодечно,</Typography>
        <Typography>ул. Великий Гостинец,</Typography>
        <Typography>д. 143б, каб. 556</Typography>
      </Stack>
    </Stack>
  );
};

export default Footer;
