import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ADMIN_ROUTE, ADMIN_ROUTE_2 } from "../../constants/routes";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { pages } from "./constants";
import PersonIcon from "@mui/icons-material/Person";
import { Link } from "react-scroll";

export const DrawerComp = ({ user, setUser, modal, signOut }) => {
  const navigate = useNavigate();

  return (
    <>
      {/* <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <LoginPage setUser={setUser} handleClose={handleClose} />
            </Box>
          </Fade>
        </Modal> */}

      {/* <SwipeableDrawer */}
      <nav
        id="mob-menu"
        // anchor="right"
        // open={openDrawer}
        // onClose={() => setOpenDrawer(false)}
        // style={{ width: "100%" }}
        position="fixed"
        color="primary"
        sx={{ bottom: "0px", height: "60px" }}
      >
        <List
          sx={{
            backgroundColor: 'var(--black-color)',
            width: "220px",
            pt: "15px",
            height: "60px",
            margin: "0 auto",
            display: "flex",
            border: 'none'
          }}
        >
          {pages.map((page, index) => (
            <ListItemButton
              key={index}
              sx={{ textAlign: "center", fontSize: "15px" }}
            >
              <ListItemIcon>
                <Link
                  to={page.href}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <img src={page.img} alt={page.title} loading="lazy" />
                  {/* <ListItemText>{page.title}</ListItemText> */}
                </Link>
              </ListItemIcon>
            </ListItemButton>
          ))}
        </List>
        {/* </SwipeableDrawer> */}
      </nav>
      {/* <IconButton
        sx={{ color: "white", marginLeft: "auto" }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon color="white" />
      </IconButton> */}
    </>
  );
};
